import React from 'react';
import './yasmin.css';

function Yasmin() {
  return (
   <div>
    Hello
   </div>
  );
}

export default Yasmin;