import React from 'react';
import './about.css';

function About() {
  return (
   <div>
    About Page
   </div>
  );
}

export default About;