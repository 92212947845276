import React from 'react';
import './contact.css';

function Contact() {
  return (
   <div>
    Contact Page
   </div>
  );
}

export default Contact;